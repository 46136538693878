import * as Sentry from '@sentry/react';

Sentry.init({
  dsn:
    'https://8a074f64e155b21bf5f665d519b6f159@o4508349499244544.ingest.de.sentry.io/4508370078859344',
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
