/* eslint-disable react/jsx-no-literals */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';

// Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import promise from 'redux-promise';

// Services
import axios from 'axios';

// Routing
import history from './utils/history';
import Routes from './routes';

// Startup to check login
import Startup from './components/Startup';

// State management (for Redux)
import store from './store';

// Notifications
import Notifications from './components/Notifications';

// Feedback
import Feedback from './components/Feedback';

import SEO from './components/SEO';

const createStoreWithMiddleware =
  process.env.REACT_APP_DEBUG === 'true'
    ? applyMiddleware(promise, thunk, logger)(createStore)
    : applyMiddleware(promise, thunk)(createStore);

// Axios
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

export default class App extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary>
        <Provider
          store={createStoreWithMiddleware(
            store,
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
              window.__REDUX_DEVTOOLS_EXTENSION__(),
          )}
        >
          <HelmetProvider>
            <SEO />
            <Notifications>
              <Startup />
              <Router history={history}>
                <Routes />
              </Router>
            </Notifications>
            <Feedback />
          </HelmetProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    );
  }
}
